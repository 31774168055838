import {ConfirmModal, Footer, FormCol, FormNumber, FormSelect, FormText, useLoader, useMessage,} from "backoffice/ui/Components";
import {Breadcrumb, Button, Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import {useNavigate, useParams} from "react-router";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {useTRPC} from "backoffice/ui/App";
import {useImmer} from "use-immer";
import {DetailTable} from "./DetailTable";
import {v4 as uuid} from "uuid";
import Big from "big.js";
import {ValidationError} from "backoffice/api/ValidationError";
import type {Outputs} from "backoffice/api/AppRouter/Loan/Project/Trade";
import {ModelObjectRCSV, Overwrite, UnArray} from "backoffice/api/types";
import type {LoanProjectType} from "backoffice/api/AppRouter/Loan/Project";
import {ExchangeStatus, LoanTradeStatus, LoanTradeType} from "src/codes";
import {useImmerChangeHandler} from "backoffice/ui/utils/react/hooks";
import {Allocation, RollbackInfo} from "backoffice/api/RDS/Models/LoanManager";
import {DividendTable} from "./DividendTable";

import "./index.scss";

/**
 * 編集モデル
 */
export type FormModel = Overwrite<Outputs['get'], {
  id?: number
  conditionId: string
  processPlan: string
  processDate: string
  dividendDate: string
  memo: string
  rollbackInfo: RollbackInfo | null

  details: Overwrite<UnArray<Outputs['get']['details']>, {
    uuid?: string
    id?: number
    since: string
    until: string
    typeId: string
    amount: string
    memo: string
    deleted: boolean
    allocations?: ModelObjectRCSV<Allocation>[]
    dividends: Record<string, string>
  }>[]
}>

/**
 * 新規詳細行
 */
export const newDetail = () => ({
  uuid     : uuid(),
  since    : '',
  until    : '',
  typeId   : '',
  amount   : '0',
  memo     : '',
  deleted  : false,
  dividends: {} as Record<string, string>
}) as UnArray<FormModel['details']>


export const LoanManagerProjectTradeFormPage = () => {

  const Loader   = useLoader(),
        Message  = useMessage(),
        Navigate = useNavigate(),
        trpc     = useTRPC();

  const projectId = Number(useParams().projectId),
        {tradeId} = useParams(),
        isNew     = tradeId === 'new';

  const [timestamp, setTimestamp]     = useState(Date.now),
        [tab, setTab]                 = useState('details'),
        [modal, setModal]             = useState(''),
        [project, setProject]         = useState(null as (LoanProjectType | null)),
        [allocations, setAllocations] = useState([] as ModelObjectRCSV<Allocation>[]),
        [model, setModel]             = useImmer({details: [newDetail()]} as FormModel),
        [error, setError]             = useState<ValidationError | null>(null),
        [pooled, setPooled]           = useState('-');

  // サーバーから取得したモデルをセット
  const setServerModel = ({...model}: Outputs['get']) => {
    const converted = {
      ...model,
      conditionId : String(model.conditionId || ''),
      processPlan : model.processPlan?.toYmd(),
      processDate : model.processDate?.toYmd(),
      dividendDate: model.dividendDate?.toYmd(),
      memo        : model.memo ?? '',

      details: model.details?.map(detail => {

        // アロケーションと分配額の抽出
        const allocations: ModelObjectRCSV<Allocation>[] = [],
              dividends: Record<string, string>          = {};

        for (const elem of detail.dividends) {
          if (elem.allocation) {
            allocations.push(elem.allocation);
          }
          dividends[elem.allocationId!] = elem.amount.toString()
        }

        return {
          ...detail,
          since      : detail.since?.toYmd(),
          until      : detail.until?.toYmd(),
          typeId     : detail.typeId.toString(),
          amount     : detail.amount?.toString(),
          deleted    : false,
          allocations: allocations.length === 0 ? undefined : allocations,
          dividends
        }
      })
    } as FormModel;

    //編集行追加
    if (!model.isConfirmed) {
      converted.details.push(newDetail());
    }
    setModel(converted);
  }

  // 初期化
  useEffect(() => {

    // データ読み込み
    Loader.task(async () => {
      try {
        if (projectId === undefined || tradeId === undefined) {
          Message.show('IDの指定が不正です', () => {
            Navigate('..');
          });
          return;
        }

        const [project, trade] = await Promise.all([
          trpc.loan.project.get.query(projectId),
          isNew || trpc.loan.project.trade.get.query({projectId, id: tradeId}),
        ]);

        setProject(project);
        if (trade !== true) {
          setServerModel(trade)
        }

        // プール金
        const pooled = (trade !== true && trade.isConfirmed) ? trade?.rollbackInfo?.pooled : project.pooled;
        setPooled(pooled?.toString().withComma ?? '-');

      } catch (err) {
        console.error(err);
        Message.error(err);
      }
    }, 300).then();
  }, [projectId, tradeId, timestamp]);


  // アロケーション読み込み
  useEffect(() => {
    const date = model.processDate ?? model.processPlan;
    if (!date) {
      return;
    }

    (async () => {
      const allocations = await trpc.loan.project.trade.allocations.query({projectId, date: new Date(date)});
      setAllocations(allocations);
    })();

  }, [projectId, model.processDate ?? model.processPlan, timestamp]);


  // 編集
  const handleChange = useImmerChangeHandler(setModel, {
    after: ({draft, name}) => {

      // 融資条件自動設定
      if (name === 'processPlan' || name === 'processDate') {
        const conditions = project?.conditions ?? null;
        if (conditions === null) {
          return;
        }

        const date = draft.processDate ?? draft.processPlan ?? null;
        if (date === null) {
          draft.conditionId = '';
        }

        const condition   = conditions.find(el => el.since.toYmd() <= date && date <= el.until.toYmd());
        draft.conditionId = String(condition?.id ?? '');
      }
    }
  });

  // 保存 サーバー側でチェックするので、クライアント側ではタイプチェックをしない
  const handleSubmit = async (statusId: LoanTradeStatus['id']) => {
    Loader.task(async () => {
      if (!model) {
        return;
      }

      // エラーを解除
      setError(null);

      try {
        await trpc.loan.project.trade.save.mutate({
          id           : model.id,
          loanProjectId: projectId,
          conditionId  : model.conditionId ? Number(model.conditionId) : null,
          processPlan  : model.processPlan ? new Date(model.processPlan) : null,
          processDate  : model.processDate ? new Date(model.processDate) : null,
          dividendDate : model.dividendDate ? new Date(model.dividendDate) : null,
          memo         : model.memo ?? null,
          statusId,

          details: (model.details)?.slice(0, -1)
                                  .map(({id, since, until, typeId, amount, memo, deleted, dividends}) => ({
                                    id,
                                    typeId,
                                    since    : new Date(since),
                                    until    : new Date(until),
                                    amount   : Big(amount),
                                    memo,
                                    deleted,
                                    dividends: Object.entries(dividends)
                                                     .map(([allocationId, amount]) => ({
                                                       allocationId: Number(allocationId),
                                                       amount      : Big(amount),
                                                     }))
                                  }))
        });

        Message.show('更新が完了しました', () => Navigate('../'));

      } catch (err) {
        const [converted, error] = ValidationError.convert(err);
        if (converted) {
          Message.error('入力内容を確認してください');
          setError(error)

          return;
        }

        console.error(err);
        Message.error(err);
      }
    }, 500).then();
  };

  // ロールバック
  const handleRollback = () => {
    Loader.task(async () => {
      try {
        await trpc.loan.trade.rollback.mutate({id: model.id!, updatedAt: model.updatedAt!});
        setTimestamp(Date.now());

      } catch (err) {
        Message.error(err);
      }
    }, 500).then();
  };

  const total = model?.details?.reduce((total, detail) => {
    if (detail.deleted) {
      return total;
    }
    return total.add(Big(detail.amount.toNumber(5) || 0));
  }, Big(0)) ?? Big(0)

  // 承認済み、最新取引、両替不要 or 両替未処理
  const canRollback = model?.isConfirmed
                      && model.id === project?.latestTradeId
                      && (model?.exchangeStatusId === ExchangeStatus['不要'].id
                          || model?.exchangeStatusId === ExchangeStatus['未処理'].id);

  return (
      <>
        <div className={'container'}>
          <Breadcrumb>
            <Breadcrumb.Item active>融資管理</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: "../.."}}>融資案件一覧</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: `../../${projectId}`}}>
              {project?.customer?.name ?? '---'} : {project?.name ?? '---'}
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{to: `../../${projectId}/trade`}}>取引一覧</Breadcrumb.Item>
            <Breadcrumb.Item active>{isNew ? '新規登録' : `編集 #${tradeId}`}</Breadcrumb.Item>
          </Breadcrumb>


          <Row className={'mt-5'}>
            <Col xl={{offset: 2, span: 8}}>
              <Container className={'small'}>
                <Row className={'my-1'}>
                  <FormCol inputId={'processPlan'}
                           title={'予定日'}
                           size={[2, 4]}
                           error={error?.get('processPlan')}>
                    <FormText type={'date'}
                              id={'processPlan'}
                              name={'processPlan'}
                              value={model?.processPlan ?? ''}
                              size={'sm'}
                              onChange={handleChange}
                              disabled={model?.isConfirmed}/>
                  </FormCol>

                  <FormCol inputId={'conditionId'}
                           title={'融資条件'}
                           size={[2, 4]}
                           error={error?.get('conditionId')}>
                    <FormSelect id={'conditionId'}
                                name={'conditionId'}
                                value={model?.conditionId}
                                size={'sm'}
                                onChange={handleChange}
                                disabled={model?.isConfirmed}>
                      <option>-</option>
                      {project?.conditions?.map(cond => <option key={cond.id} value={cond.id}>{cond.name}</option>)}
                    </FormSelect>
                  </FormCol>
                </Row>

                <Row className={'my-1'}>
                  <FormCol inputId={'processDate'}
                           title={'取引日'}
                           size={[2, 4]}
                           error={error?.get('processDate')}>
                    <FormText type={'date'}
                              id={'processDate'}
                              name={'processDate'}
                              value={model?.processDate ?? ''}
                              size={'sm'}
                              onChange={handleChange}
                              disabled={model?.isConfirmed}/>
                  </FormCol>

                  <FormCol inputId={'statusId'}
                           title={'ステータス'}
                           size={[2, 4]}
                           error={error?.get('statusId')}>
                    <FormText id={'statusId'}
                              name={'statusId'}
                              value={LoanTradeStatus.find(model?.statusId)?.value ?? '-'}
                              size={'sm'}
                              readOnly
                              disabled={model?.isConfirmed}/>
                  </FormCol>
                </Row>

                <Row className={'my-1'}>
                  <FormCol inputId={'dividendDate'}
                           title={'分配対象日'}
                           size={[2, 4]}
                           error={error?.get('dividendDate')}>
                    <FormText type={'date'}
                              id={'dividendDate'}
                              name={'dividendDate'}
                              value={model?.dividendDate ?? ''}
                              size={'sm'}
                              onChange={handleChange}
                              disabled={model?.isConfirmed}/>
                  </FormCol>
                </Row>

                <Row className={'my-1'}>
                  <FormCol inputId={'memo'}
                           title={'メモ'}
                           size={[2, 10]}
                           error={error?.get('memo')}>
                    <FormText id={'memo'}
                              style={{minWidth: 0}}
                              name={'memo'}
                              value={model?.memo ?? ''}
                              size={'sm'}
                              onChange={handleChange}
                              disabled={model?.isConfirmed}/>
                  </FormCol>
                </Row>

                {/* プール金 */}
                {pooled !== '-' && (
                    <>
                      <hr/>
                      <Row className={'my-1'}>
                        <FormCol inputId={'pooled'}
                                 title={'プール金'}
                                 size={[2, 4]}>

                          <FormText id={'pooled'}
                                    style={{minWidth: 0}}
                                    name={'pooled'}
                                    value={pooled}
                                    size={'sm'}
                                    disabled/>
                        </FormCol>
                      </Row>
                    </>
                )}

              </Container>

            </Col>
          </Row>

          <Row className={'mt-5 small'}>
            <Tabs activeKey={tab}
                  onSelect={k => setTab(k || 'details')}
                  className="mb-3">
              <Tab eventKey="details" title="取引詳細">
                <DetailTable project={project}
                             model={model}
                             setModel={setModel}
                             error={error}/>
              </Tab>

              {model.details.map(((detail, index) => {

                // 融資は表示しない
                if (detail.typeId === String(LoanTradeType['融資'].id)) {
                  return;
                }

                const isRefund = detail.typeId === String(LoanTradeType['元本返済'].id);

                // 削除済み、or (承認前 and 元本返済以外 and 分配なし)
                if (detail.deleted || (!model.isConfirmed && !isRefund && Object.keys(detail.dividends).length === 0)) {
                  return;
                }

                // 承認前はタイトルに金額を表示
                let title = `#${index + 1} ${LoanTradeType.find(detail.typeId).value}`;
                if (!model.isConfirmed) {
                  const dived = Object.values(detail.dividends).reduce((dived, amount) => dived.add(amount.toNumber(5) || 0), Big(0));

                  title = `${title} ${project?.currency.prefix}${detail.amount.toString().withComma} / 分配額 ${project?.currency.prefix}${dived.toString().withComma}`
                }

                return (
                    <Tab key={detail.uuid || detail.id} eventKey={`dividend-${index}`} title={title}>
                      <DividendTable project={project}
                                     allocations={detail.allocations ?? allocations}
                                     model={model}
                                     detailIndex={index}
                                     setModel={setModel}
                                     error={error}/>
                    </Tab>
                )
              }))}
            </Tabs>
          </Row>
        </div>

        <Footer>
          <div className={'d-flex align-items-center m-2'}>
            <label className={'me-2'}>合計</label>
            <div style={{width: "7em"}}>
              <FormNumber size={'sm'} align={'end'}
                          value={total.toString()}
                          precision={(project?.bondCurrency ?? project?.currency)?.precision ?? 0}
                          readOnly/>
            </div>
          </div>

          <Button variant="secondary"
                  className={'m-1'}
                  style={{minWidth: 100}}
                  size={'sm'}
                  onClick={() => handleSubmit(LoanTradeStatus['予定'].id)}
                  disabled={model?.isConfirmed}>
            予定登録
          </Button>

          <Button variant="primary"
                  className={'m-1'}
                  style={{minWidth: 100}}
                  size={'sm'}
                  onClick={() => handleSubmit(LoanTradeStatus['実績（承認待ち）'].id)}
                  disabled={model?.isConfirmed}>
            実績登録
          </Button>

          {canRollback && (
              <>
                <Button variant="outline-danger"
                        className={'m-1'}
                        style={{minWidth: 100}}
                        size={'sm'}
                        onClick={() => setModal('rollback')}>
                  承認取消
                </Button>

                <ConfirmModal show={modal === 'rollback'}
                              onConfirm={() => {
                                setModal('');
                                handleRollback()
                              }}
                              onCancel={() => setModal('')}
                              confirmLabel={'承認を取り消す'}
                              confirmButtonProps={{variant: 'danger'}}
                              keyword={'ロールバック'}>
                  本融資取引の承認を取り消しますか？
                </ConfirmModal>
              </>
          )}
        </Footer>
      </>
  );
}

